<script>
import { required, email } from "vuelidate/lib/validators";
import { authMethods } from "@/state/helpers";

import { notificationMethods } from "@/state/helpers";

export default {
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authenticating: false,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required, email },
    password: { required },
  },
  methods: {
    ...notificationMethods,
    ...authMethods,
    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const { email, password, generateAPI } = this;
        if (email && password) {
          this.authenticating = true;
          const res = await this.login({ email, password, generateAPI });
          if (res.isAuth) {
            this.$bvToast.toast("Authenticated successfully.", {
              title: "Authentication",
              variant: "success",
              solid: true,
            });
          } else {
            this.$bvToast.toast(`Authentication failed! , ${res.message}`, {
              title: "Authentication",
              variant: "danger",
              solid: true,
            });
          }

          this.authenticating = false;
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="authentication-bg col-lg-7">
            <img src="@/assets/images/bg-login.svg" alt="" srcset="" />
          </div>
          <div class="col-lg-5 col-md-12">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div class="login-container">
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <span style="font-size: 32px; font-weight: 900">
                              B-SEEN
                            </span>
                          </a>
                        </div>
                        <p>Sign in to continue to B-SEEN.</p>
                      </div>

                      <b-alert
                        variant="danger"
                        class="mt-3"
                        v-if="notification.message"
                        show
                        dismissible
                        >{{ notification.message }}</b-alert
                      >

                      <div class="p-2 mt-3">
                        <form
                          class="form-horizontal sign-in"
                          @submit.prevent="tryToLogIn"
                        >
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">Email</label>
                            <input
                              type="email"
                              v-model="email"
                              class="form-control"
                              id="email"
                              placeholder="Enter email"
                              :class="{
                                'is-invalid': submitted && $v.email.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.email.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.email.required"
                                >Email is required.</span
                              >
                              <span v-if="!$v.email.email"
                                >Please enter valid email.</span
                              >
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">Password</label>
                            <input
                              v-model="password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{
                                'is-invalid': submitted && $v.password.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >
                              Password is required.
                            </div>
                          </div>

                          <div
                            style="
                              display: flex;
                              flex-wrap: wrap;
                              justify-content: space-between;
                            "
                          >
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customControlInline"
                              />
                              <label
                                class="custom-control-label"
                                for="customControlInline"
                                >Remember me</label
                              >
                            </div>

                            <div class="text-center">
                              <button
                                class="
                                  btn btn-primary
                                  w-md
                                  waves-effect waves-light
                                "
                                style="height: 32px; line-height: 12px"
                                type="submit"
                              >
                                <span v-if="authenticating"
                                  >Authenticating...</span
                                >
                                <span v-else> Log In</span>
                              </button>
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <router-link
                              tag="a"
                              to="/forgot-password"
                              class="text-muted"
                            >
                              <i class="mdi mdi-lock mr-1"></i> Forgot your
                              password?
                            </router-link>
                          </div>
                        </form>
                      </div>

                      <div class="mt-3 text-center">
                        <p>© 2022 B-SEEN</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.sign-in {
  max-width: 420px;
  margin: auto;
}
</style>
